import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { Heading } from '../heading';
import { useGraphQL } from '../../hooks';

function EndOfYearPromotion() {
  const { marcosPaulo } = useGraphQL();
  return (
    <article className="relative flex flex-col justify-between overflow-hidden">
      <div className="absolute inset-0 flex">
        <GatsbyImage
          fluid={marcosPaulo.childImageSharp.fluid}
          className="flex-1"
        />
        <div className="absolute inset-0 bg-black bg-opacity-75 pointer-events-none bg-gradient-to-t from-black via-transparent to-black sm:bg-transparent" />
      </div>
      <div className="relative px-4 py-12 sm:px-6 lg:px-8 text-shadow">
        <Heading eyebrowText="" tag="h1" reverse>
          GRAND OPENING OFFER{' '}
        </Heading>
        <div className="mx-auto mt-12 text-xl prose text-center text-white">
          <p>
            Get a fresh start and take your fitness to the next level with
            nXtLvL!
          </p>
          <p>
            Anyone who signs up to our membership will receive a 5%/week
            discount off their membership.
          </p>
          <p>Terms and conditions apply</p>
          <p>Offer ends 31 August 2024</p>
        </div>
        <Link
          to="/contact/"
          className="block w-full max-w-xs px-4 py-4 mx-auto my-24 text-2xl font-bold leading-none tracking-widest text-center uppercase transition duration-300 ease-in-out border-2 border-white hover:bg-white hover:text-black focus:bg-white focus:text-black"
        >
          Redeem Your Offer
        </Link>
      </div>
    </article>
  );
}

export { EndOfYearPromotion };
